import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import App from './App';
import { store } from './store/store';
import { theme } from './utils/theme';
import reportWebVitals from './reportWebVitals';
import './index.scss';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCaoBjYN42OF3wdYCPRIzC8VKl1Axnd-Ek",
	authDomain: "ultraguard-369308.firebaseapp.com",
	projectId: "ultraguard-369308",
	storageBucket: "ultraguard-369308.appspot.com",
	messagingSenderId: "401310075364",
	appId: "1:401310075364:web:c93b762f0473dd42475ff1",
	measurementId: "G-MRLQLW7RDY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	/*<React.StrictMode>*/
	<Router>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</Provider>
	</Router>
	/*</React.StrictMode>*/
);

reportWebVitals();
