import React from 'react';
import { Navigate } from 'react-router-dom';
import Toast from 'components/common/Toast';
import Login from 'pages/Login';
import useAuth from 'hooks/useAuth';

const AuthLayout = () => {
	const auth = useAuth();
	return auth ? (
		<Navigate to='/detections' />
	) : (
		<>
			<Toast />
			<Login />
		</>
	);
};

export default AuthLayout;
