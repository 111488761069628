import AxiosInstance from 'utils/Instance';
import {
	DETECTION_CURRENT,
	DETECTIONS,
	DETECTIONS_LIMIT,
	INVESTIGATION_CURRENT,
	INVESTIGATIONS,
	THREAT_CURRENT,
	THREATS,
} from 'urls/detections';

export const getDetections = () => AxiosInstance.get(DETECTIONS_LIMIT);
export const getAlbumImages = params => AxiosInstance.get(DETECTIONS, params);
export const getInvestigations = params => AxiosInstance.get(INVESTIGATIONS, params);
export const getThreats = params => AxiosInstance.get(THREATS, params);
export const updateDetection = (id, body) => AxiosInstance.put(DETECTION_CURRENT(id), body);
export const updateThreatEvent = (id, body) => AxiosInstance.put(THREAT_CURRENT(id), body);
export const updateInvestigationEvent = (id, body) =>
	AxiosInstance.put(INVESTIGATION_CURRENT(id), body);
