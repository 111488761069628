import { configureStore } from '@reduxjs/toolkit';
import detectionsSlice from './detections/detectionsSlice';
import inspectionSlice from './inspection/inspectionSlice';
import timelineSlice from './timeline/timelineSlice';
import clientSlice from './clients/clientSlice';
import toastSlice from './toast/toastSlice';
import authSlice from './auth/authSlice';
import { baseAPi } from './apis/baseApi';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		clients: clientSlice,
		detections: detectionsSlice,
		inspection: inspectionSlice,
		timeline: timelineSlice,
		toast: toastSlice,
		[baseAPi.reducerPath]: baseAPi.reducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(baseAPi.middleware),
});
