import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import Welcome from 'components/Welcome';
import Toast from 'components/common/Toast';
import { clientsSelector } from 'helpers/reduxSelectors';
import { fetchUser } from 'store/auth/thunks';
import './Home.scss';

const HomeLayout = () => {
	const { chosenClients } = useSelector(clientsSelector);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUser());
	}, []);

	return (
		<div className='home'>
			<Sidebar />
			<div className='content'>
				<Toast />
				<Header />
				<main className='main'>
					{!chosenClients.length && <Welcome />}
					{!!chosenClients.length && <Outlet />}
				</main>
			</div>
		</div>
	);
};

export default HomeLayout;
