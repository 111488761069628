import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './hoc/protectedRoute';
import HomeLayout from './layouts/Home';
import AuthLayout from './layouts/Auth';
import { ROUTES, Login } from './router';
import './App.css';

const App = () => (
	<>
		<Routes>
			<Route path='' element={<ProtectedRoute />}>
				<Route path='' element={<HomeLayout />}>
					{ROUTES.map(item => (
						<Route key={item.id} {...item} />
					))}
				</Route>
			</Route>
			<Route path='auth' element={<AuthLayout />}>
				<Route path='login' element={<Login />} />
			</Route>
		</Routes>
	</>
);

export default App;
