const initialState = {
	loading: false,
	clientsSite: [
		{
			id: '1',
			name: 'K Shane Estate',
			site_coordinates: {
				lat: -25.757329,
				lng: 27.806423,
			},
			site: 'KSH001',
			selected: false,
			hover: false,
			status: 'online',
			//will be deleted,must be detection count from another slice
			detection: 0,
		},
	],
	/*clientsSite: [
		{
			id: '1',
			name: 'K Shane Estate',
			site_coordinates: {
				lat: -25.757329,
				lng: 27.806423,
			},
			site: 'KSH001',
			selected: false,
			hover: false,
			status: 'online',
			//will be deleted,must be detection count from another slice
			detection: 0,
		},
		{
			id: '2',
			name: 'White House',
			site_coordinates: {
				lat: 38.897957,
				lng: -77.03656,
			},
			site: 'WH001',
			selected: false,
			hover: false,
			status: 'online',
			//will be deleted,must be detection count from another slice
			detection: 1,
		},
		{
			id: '3',
			name: 'Buckingham Palace',
			site_coordinates: {
				lat: 51.501476,
				lng: -0.140634,
			},
			site: 'BHP001',
			selected: false,
			hover: false,
			status: 'online',
			//will be deleted,must be detection count from another slice
			detection: 99,
		},
		{
			id: '4',
			name: 'Client Site',
			site_coordinates: {},
			site: 'CS001',
			selected: false,
			hover: false,
			status: 'offline',
			//will be deleted,must be detection count from another slice
			detection: 0,
		},
	],*/
	chosenClients: [],
	cancelId: null,
	selectAll: false,
	groupByPoints: {
		BHP001: [
			{ id: 1, cn: 'online', lat: 51.501994, lng: -0.139851, hover: false },
			{ id: 2, cn: 'online', lat: 51.501843, lng: -0.138231, hover: false },
		],
		KSH001: [
			{ id: 1, cn: 'online', lat: -25.753949, lng: 27.80921, hover: false },
			{ id: 2, cn: 'online', lat: -25.755335, lng: 27.808733, hover: false },
			{ id: 3, cn: 'offline', lat: -25.754233, lng: 27.807765, hover: false },
			{ id: 4, cn: 'waiting', lat: -25.756063, lng: 27.807118, hover: false },
			{ id: 5, cn: 'waiting', lat: -25.754223, lng: 27.805752, hover: false },
			{ id: 6, cn: 'unverified', lat: -25.756584, lng: 27.806811, hover: false },
		],
		WH001: [{ id: 1, cn: 'online', lat: 38.898391, lng: -77.036399, hover: false }],
	},
};

export default initialState;
