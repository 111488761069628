import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { MARKER_LABELS } from 'helpers/constants';
const { ONLINE } = MARKER_LABELS;

const clientsSlice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		selectClient: (state, { payload }) => {
			state.clientsSite = state.clientsSite.map(item => {
				if (item.id === payload) {
					return { ...item, selected: !item.selected };
				}
				return item;
			});
		},
		mouseMoveClient: (state, { payload }) => {
			state.clientsSite = state.clientsSite.map(item => {
				if (item.id === payload) {
					return { ...item, hover: !item.hover };
				}
				return item;
			});
		},
		addToChosen: (state, { payload }) => {
			const selected = state.clientsSite.find(item => item.id === payload);
			if (selected.selected) {
				state.chosenClients.push({
					id: selected.id,
					name: selected.name,
					site: selected.site,
					site_coordinates: selected.site_coordinates,
				});
			} else {
				state.chosenClients = state.chosenClients.filter(el => el.id !== selected.id);
			}
			state.selectAll =
				state.clientsSite.filter(el => el.status === ONLINE).length === state.chosenClients.length;
		},
		selectAllClient: (state, { payload }) => {
			state.selectAll = payload;
			state.clientsSite = state.clientsSite.map(item => ({ ...item, selected: state.selectAll }));
			if (state.selectAll) {
				state.chosenClients = state.clientsSite.filter(item => item.status === ONLINE);
			} else {
				state.chosenClients = [];
			}
		},
		cancel: (state, { payload }) => {
			state.cancelId = payload;
		},
		selectNext: (state, { payload }) => {
			state.chosenClients = state.chosenClients.filter(item => item.id === payload);
			state.clientsSite = state.clientsSite.map(item => {
				if (item.id !== payload) {
					return { ...item, selected: false };
				}
				return item;
			});
		},
		leaveAllAlone: (state, { payload }) => {
			state.selectAll = false;
			state.chosenClients = state.chosenClients.filter(el => el.id === payload);
			state.clientsSite = state.clientsSite.map(item => {
				if (item.id !== payload) {
					return { ...item, selected: !item.selected };
				} else {
					return item;
				}
			});
		},
		mouseMoveMarker: (state, { payload }) => {
			const [lat, lng] = payload.split(',');
			state.groupByPoints[state.chosenClients[0].site] = state.groupByPoints[
				state.chosenClients[0].site
			].map(item => {
				if (item.lng === +lng && item.lat === +lat) {
					return { ...item, hover: !item.hover };
				}
				return item;
			});
		},
	},
});

export const {
	selectClient,
	addToChosen,
	selectAllClient,
	mouseMoveClient,
	cancel,
	selectNext,
	leaveAllAlone,
	mouseMoveMarker,
} = clientsSlice.actions;
export default clientsSlice.reducer;
