import React, { useState } from 'react';
import Modal from 'components/common/Modal';
import Logout from './components/Logout';
import help from 'assets/images/icons/help.svg';
import profile from 'assets/images/icons/user_profile.svg';

const ProfileInfo = () => {
	const [openModal, setOpenModal] = useState(false);

	const openModalHandler = () => {
		setOpenModal(prev => !prev);
	};

	return (
		<>
			<div className='sidebar-bottom__nav'>
				<img src={help} alt='help' />
				<img src={profile} alt='user profile' onClick={openModalHandler} />
			</div>
			<Modal open={openModal} size='sm'>
				<Logout onClose={openModalHandler} />
			</Modal>
		</>
	);
};

export default ProfileInfo;
