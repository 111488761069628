import { createSelector } from '@reduxjs/toolkit';

export const clientsSelector = ({ clients }) => clients;
export const detectionsSelector = ({ detections }) => { return detections; };
export const inspectionSelector = ({ inspection }) => inspection;
export const timelineSelector = ({ timeline }) => timeline;
export const toastSelector = ({ toast }) => toast;
export const authSelector = ({ auth }) => auth;

export const eventSelectedStepSelector = createSelector(
	({ detections }) => detections.eventSelectedSteps,
	({ detections }) => detections.currentStep,
	(steps, number) => steps.find(({ stepNumber }) => stepNumber === number)
);

export const filteredEventsSelector = createSelector(
	({ detections }) => detections.events,
	items => items.filter(({ active }) => active)
);

export const collationsSelector = createSelector(
	({ detections }) => detections.detections,
	({ detections }) => detections.collationId,
	(items, id) =>
		items.filter(({ detid }) => detid === id).map((item, index) => ({ ...item, idx: index + 1 }))
);
