import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { fetchUser, signIn } from './thunks';
//import TokenService from 'utils/TokenService';

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			//state.token = TokenService.removeLocalAccessToken();
			state.token = sessionStorage.removeItem('token');
		},
	},
	extraReducers: {
		[signIn.pending]: state => {
			state.loading = true;
		},
		[signIn.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.token = payload;
			state.error = '';
		},
		[signIn.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		[fetchUser.pending]: state => {
			state.loading = true;
		},
		[fetchUser.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.user = payload;
			state.error = '';
		},
		[fetchUser.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
	},
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
