import React from 'react';
import { Dialog as MUIDialog } from '@mui/material';
import './Dialog.scss';

const Dialog = ({ onClose, open, title, children, renderLeftBtn, renderRightBtn }) => {
	const handleClose = () => {
		onClose?.(false);
	};
	return (
		<MUIDialog onClose={handleClose} open={open}>
			<div className='dialog'>
				<h2 className='dialog-title'>{title}</h2>
				<div className='dialog-content'>{children}</div>
				<div className='dialog-actions'>
					{renderLeftBtn?.()}
					{renderRightBtn?.()}
				</div>
			</div>
		</MUIDialog>
	);
};

export default Dialog;
