import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	toastOpen: false,
	toastMessage: 'successfully',
	toastType: 'success',
};

const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		showToast: (state, { payload }) => {
			state.toastOpen = true;
			state.toastMessage = payload.message;
			state.toastType = payload.type;
		},
		clearToast: state => {
			state.toastOpen = false;
		},
	},
});

export const { showToast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;
