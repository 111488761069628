import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const inspectionSlice = createSlice({
	name: 'inspection',
	initialState,
	reducers: {
		changeType: (state, { payload }) => {
			state.isType = !state.isType;
			state.type = payload;
		},
		cancel: state => {
			state.isType = false;
			state.type = '';
		},
	},
});

export const { cancel, changeType } = inspectionSlice.actions;
export default inspectionSlice.reducer;
