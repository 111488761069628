import React from 'react';
import './Welcome.scss';

const Welcome = () => (
	<div className='select-client'>
		<h2 className='select-client__text' data-testid='select-client'>
			Select Client
		</h2>
	</div>
);

export default Welcome;
