import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const timelineSlice = createSlice({
	name: 'timeline',
	initialState,
	reducers: {
		addActiveDetectionDeprecated: (state, { payload }) => {
			state.activeDetectionDeprecated = payload;
		},
		removeActiveDetectionDeprecated: state => {
			state.activeDetectionDeprecated = '';
		},
	},
});

export const { addActiveDetectionDeprecated, removeActiveDetectionDeprecated } =
	timelineSlice.actions;
export default timelineSlice.reducer;
