import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { clearToast } from 'store/toast/toastSlice';
import { toastSelector } from 'helpers/reduxSelectors';
import './Toast.scss';

const Toast = () => {
	const { toastMessage, toastOpen, toastType } = useSelector(toastSelector);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(clearToast());
	};

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={toastOpen}
			autoHideDuration={3000}
			onClose={handleClose}
		>
			<Alert
				className='toast'
				variant='filled'
				severity={toastType}
				iconMapping={{
					warning: <div className='toast-icon'>!</div>,
					info: <div className='toast-icon'>!</div>,
					success: <div className='toast-icon'>!</div>,
				}}
			>
				{toastMessage}
			</Alert>
		</Snackbar>
	);
};

export default Toast;
