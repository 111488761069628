import React, { memo, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Badge from 'components/common/Badge';
import Dialog from 'components/common/Dialog';
import InfoHeading from 'components/InfoHeading';
import { addToChosen, cancel, mouseMoveClient, selectClient } from 'store/clients/clientSlice';
import { MARKER_LABELS, THEME_COLORS, URLS } from 'helpers/constants';
import './ClinetItem.scss';

const { OFFLINE } = MARKER_LABELS;
const { DANGER } = THEME_COLORS;
const { DETECTIONS_URL } = URLS;

const ClientItem = ({
	item: { id, selected, detection, hover, name, status },
	clientUID,
	setWidth,
}) => {
	const [openDialog, setOpenDialog] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === DETECTIONS_URL) {
			selectClientHandler(clientUID)();
		}
	}, []);

	const selectClientHandler = (id, status) => () => {
		if (isClientOffline(status)) {
			setOpenDialog(prev => !prev);
		} else {
			dispatch(selectClient(id));
			dispatch(addToChosen(id));
			dispatch(cancel(id));
		}
	};

	const mouseMoveHandler = id => () => {
		setWidth(prev => !prev);
		dispatch(mouseMoveClient(id));
	};

	const transformNameHandler = fullName => {
		const shortName = fullName.split(' ');
		return `${shortName[0][0]}${shortName[1][0]}`;
	};

	const isClientOffline = status => status === OFFLINE;

	const memoizedTransformName = useMemo(() => transformNameHandler(name), [name]);

	return (
		<>
			<div className='client-panel__main-item' key={id}>
				<div
					className={`client-panel__main-item__inner ${
						selected && !isClientOffline(status) ? 'selected' : ''
					} ${isClientOffline(status) ? 'no-signal' : ''}`}
					onClick={selectClientHandler(id, status)}
					onMouseEnter={mouseMoveHandler(id)}
					onMouseLeave={mouseMoveHandler(id)}
				>
					{!!detection && <Badge items={detection} variant={hover && 'dot'} />}
					<p className='client-panel__main-item__inner-text'>
						{hover && name}
						{!hover && memoizedTransformName}
					</p>
				</div>
			</div>
			<Dialog
				open={openDialog}
				title={<InfoHeading text='Client Offline' color='dark' type={DANGER} />}
				renderRightBtn={() => (
					<button className='btn-primary-outline' onClick={() => setOpenDialog(prev => !prev)}>
						Dismiss
					</button>
				)}
			>
				<p>
					Ultraguard cannot establish a connection with the client. This could be due to a faulty
					internet connection. Please contact the client urgently to resolve.
				</p>
			</Dialog>
		</>
	);
};

export default memo(ClientItem);
