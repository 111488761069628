import React from 'react';
import ClientPanel from './components/ClientPanel';
import ProfileInfo from './components/ProfileInfo';
import logo from 'assets/images/logos/logo.png';
import './Sidebar.scss';

const Sidebar = () => (
	<div className='sidebar'>
		<div className='sidebar-top'>
			<div className='sidebar-top__logo'>
				<img src={logo} alt='ultrasense' />
				<div className='sidebar-top__logo-line'></div>
			</div>
			<ClientPanel />
		</div>
		<div className='sidebar-bottom'>
			<div className='sidebar-bottom__line'></div>
			<ProfileInfo />
		</div>
	</div>
);

export default Sidebar;
