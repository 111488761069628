import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

export const Detections = lazy(() => import('pages/Detections'));
export const Map = lazy(() => import('pages/Map'));
export const Reporting = lazy(() => import('pages/Reporting'));
export const Login = lazy(() => import('pages/Login'));

export const ROUTES = [
	{
		id: 'home',
		path: '',
		element: <Navigate to='detections' />,
	},
	{
		id: 'detections',
		path: 'detections',
		element: (
			<Suspense fallback={<div className='loader'></div>}>
				<Detections />
			</Suspense>
		),
	},
	{
		id: 'map',
		path: 'map',
		element: (
			<Suspense fallback={<div className='loader'></div>}>
				<Map />
			</Suspense>
		),
	},
	{
		id: 'reporting',
		path: 'reporting',
		element: (
			<Suspense fallback={<div className='loader'></div>}>
				<Reporting />
			</Suspense>
		),
	},
];
