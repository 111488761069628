const initialState = {
	loading: false,
	updateLoading: false,
	detections: [],
	isEventPanel: false,
	events: [],
	chosenEvent: {},
	eventSelectedSteps: [],
	filteredDetections: [],
	filteredEvents: [],
	currentStep: -1,
	chosenDetection: {},
	filterBy: ['unverified'],
	filterEvents: 'active',
	filterResolved: ['investigate'],
	collationId: '',
	collation: [],
	threatModeActive: false,
	investigationModeActive: false,
	live: true,
	stepId: '',
	error: '',
	album: [],
	total: 0,
};

export default initialState;
