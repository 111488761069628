import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => (
	<footer className='footer'>
		<ul className='footer-list'>
			<li className='footer-list__item'>
				<Link className='footer-list__item-link' to=''>
					System Status
				</Link>
			</li>
			<li className='footer-list__item'>
				<Link className='footer-list__item-link' to=''>
					Privacy Policy
				</Link>
			</li>
			<li className='footer-list__item'>
				<Link className='footer-list__item-link' to=''>
					Terms & Conditions
				</Link>
			</li>
		</ul>
		<p className='footer-copyright'>
			Copyright &copy; {new Date().getFullYear()} Ultrasense (Pty) Ltd. All rights reserved.
		</p>
	</footer>
);

export default Footer;
