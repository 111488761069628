import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUser, login } from 'services/auth-service';
import TokenService from 'utils/TokenService';

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
	return login(data)
		.then(({ data: { token } }) => {
			sessionStorage.setItem('token', token);
			//TokenService.setLocalAccessToken(token);
			return token;
		})
		.catch(e => rejectWithValue(e.response.data.message));
});

export const fetchUser = createAsyncThunk('auth/fetchUser', async (_, { rejectWithValue }) => {
	return getUser()
		.then(({ data }) => data)
		.catch(e => rejectWithValue(e));
});
