import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterAll from './components/FilterAll';
import ClientItem from './components/ClientItem';
import useOnViewPort from 'hooks/useOnViewPort';
import { clientsSelector } from 'helpers/reduxSelectors';
import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';
import './ClientPanel.scss';

const ClientPanel = () => {
	const [width, setWidth] = useState(false);
	const [scroll, setScroll] = useState(false);

	const containerRef = useRef(null);

	const isIntersecting = useOnViewPort(containerRef);
	const { clientsSite } = useSelector(clientsSelector);

	useEffect(() => {
		if (containerRef.current) {
			setScroll(false);
		}

		if (!isIntersecting) {
			setScroll(true);
		}
	}, [isIntersecting]);

	return (
		<div className='client-panel'>
			<FilterAll />
			<div className={`client-panel__main ${width ? 'full' : ''} ${scroll ? 'fade' : ''}`}>
				{clientsSite.map(item => (
					<ClientItem
						key={item.id}
						item={item}
						clientUID={clientsSite[0]?.id}
						setWidth={setWidth}
					/>
				))}
				<div className='client-panel__add'>
					<p className='client-panel__add-text'>
						<Plus className='client-panel__add-icon' />
					</p>
				</div>
				<div ref={containerRef}></div>
			</div>
		</div>
	);
};

export default ClientPanel;
