import { ReactComponent as Pen } from 'assets/images/icons/pen.svg';
import { ReactComponent as Camera } from 'assets/images/icons/camera.svg';
import { ReactComponent as Patrol } from 'assets/images/icons/patroller.svg';

export const EVENTS_TASKBAR = [
	{ step: 1, title: 'Quick Note', icon: <Pen /> },
	{ step: 2, title: 'Camera Investigation', icon: <Camera className='camera-icon' /> },
	{ step: 3, title: 'Patroller Investigation', icon: <Patrol /> },
];

export const THEME_COLORS = {
	PRIMARY: '#0A2540',
	SECONDARY: '#737F8B',
	WARNING: '#FFA956',
	DANGER: '#D53235',
	SUCCESS: '#6AFF8F',
	INFO: '#00D4FF',
};

export const APP_NAMES = {
	ACTIVE: 'active',
	RESOLVED: 'resolved',
	OTHER: 'other',
	UNVERIFIED: 'unverified',
	VERIFIED: 'verified',
	NEW: 'new',
};

export const TOAST_TYPE = {
	SUCCESS: 'success',
	ERROR: 'error',
	INFO: 'info',
	WARNING: 'warning',
};

export const URLS = {
	DETECTIONS_URL: '/detections',
	MAP_URL: '/map',
	LOGIN: '/auth/login',
};

export const DETECTION_LABELS = {
	INVESTIGATE: 'investigate',
	THREAT: 'threat',
	SAFE: 'safe',
	OTHER: 'other',
	RESOLVED: 'resolved',
};

export const MARKER_LABELS = {
	UNVERIFIED: 'unverified',
	ONLINE: 'online',
	WAITING: 'waiting',
	OFFLINE: 'offline',
};

export const CONFIRM_TYPES = {
	OBJECT: 'Other',
	HUMAN: 'Human',
};

export const OBJECT_TYPES = ['Rock', 'Shadow', 'Plant', 'Tree', 'Pillar', 'unknown', 'other'];

export const FILTER_BUTTONS = [
	{ title: 'unverified', cn: 'btn-warning' },
	{ title: 'other', cn: 'btn-primary' },
	{ title: 'safe', cn: 'btn-success' },
];

export const HUMAN_STATUS = [
	{ status: 'safe', cn: 'status-safe' },
	{ status: 'investigate', cn: 'status-investigate' },
	{ status: 'threat', cn: 'status-threat' },
];

export const THREAT_MODE = [
	{ status: 'Intruder', cn: 'status-intruder' },
	{ status: 'Civilian', cn: 'status-civilian' },
	{ status: 'Guard', cn: 'status-civilian' },
];

export const EVENT_FILTER_BUTTONS = [
	{ name: 'active', cn: 'btn-danger' },
	{ name: 'resolved', cn: 'btn-success' },
];

export const RESOLVED_FILTER_BUTTONS = [
	{ name: 'investigate', cn: 'event-warning' },
	{ name: 'threat', cn: 'event-danger' },
];

export const MAP_FILTER_BUTTONS = [
	{ name: 'new', cn: 'btn-info' },
	{ name: 'verified', cn: 'btn-info' },
];

export const ARRIVE_TYPES = {
	ARRIVED: 'Arrived',
	NOT_ARRIVED: 'Not Arrived',
};

export const KEY_CODES = {
	arrowLeft: 'ArrowLeft',
	arrowRight: 'ArrowRight',
	arrowDown: 'ArrowDown',
	arrowUp: 'ArrowUp',
};

export const FIRE_DB_TYPES = {
	STRING_VALUE: 'stringValue',
	BOOLEAN_VALUE: 'booleanValue',
	INTEGER_VALUE: 'integerValue',
	DOUBLE_VALUE: 'doubleValue',
	ARRAY_VALUE: 'arrayValue',
	MAP_VALUE: 'mapValue',
};

export const TIME_UNITS = {
	YEARS: 'years ago',
	YEAR: 'year ago',
	MONTHS: 'months ago',
	MONTH: 'month ago',
	WEEKS: 'weeks ago',
	WEEK: 'week ago',
	DAYS: 'days ago',
	DAY: 'day ago',
	HOURS: 'hours ago',
	HOUR: 'hour ago',
	MINUTES: 'minutes ago',
	MINUTE: 'minute ago',
	NOW: 'Just now',
};

export const DAY_UNITES = {
	TODAY: 'Today',
	YESTERDAY: 'Yesterday',
};

export const VALIDATE_MESSAGES = {
	FIELD_REQUIRED: 'This field is required',
	INVALID_EMAIL: 'Please enter a valid email',
	OBJECT_TYPE_REQUIRED: 'Please choose an object type!',
	HUMAN_STATUS_REQUIRED: 'Please choose an human status!',
	THREAT_MODE_REQUIRED: 'Please choose an threat mode!',
	AUTHENTICATION_ERROR: 'Authentication error',
};

export const MESSAGES = {
	IS_THREAT: 'New THREAT detected at',
	IS_INVESTIGATE: 'INVESTIGATE new detection at',
	IS_NEW: 'New detection at',
	IS_SAFE: 'New detection verified SAFE at',
	IS_UNVERIFIED: 'New unverified detections at',
	IS_OTHER: 'New detection verified OTHER at',
	ACTIVE_THREAT_MODE: 'DETECTION THAT IS LINKED TO THE EVENT',
};

export const DATE_RANGE = [
	{ value: '', title: 'Select date range' },
	{ value: 'current', title: 'Current shift' },
	{ value: 'previous', title: 'Previous shift' },
	{ value: 'week', title: 'Week to date' },
	{ value: 'last-week', title: 'Last week' },
	{ value: 'month', title: 'Month to date' },
	{ value: 'last-month', title: 'Last month' },
	{ value: 'year', title: 'Year to date' },
	{ value: 'last-year', title: 'Last Year' },
	{ value: 'custom', title: 'Custom' },
];
