import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvestigations, fetchThreats } from 'store/detections/thunks';
import { setActiveMode, setInvestigationActiveMode } from 'store/detections/detectionsSlice';
import { detectionsSelector } from 'helpers/reduxSelectors';
import './ThreatActive.scss';

const ThreatActive = () => {
	const { chosenClients, threatModeActive, investigationModeActive } = useSelector(detectionsSelector);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchThreats({ chosenClients, active: true }))
			.unwrap()
			.then(res => {
				if (res.length) {
					dispatch(setActiveMode(true));
				} else {
					dispatch(setActiveMode(false));
				}
			});
		dispatch(fetchInvestigations({ chosenClients, active: true }))
			.unwrap()
			.then(res => {
				if (res.length) {
					dispatch(setInvestigationActiveMode(true));
				} else {
					dispatch(setInvestigationActiveMode(false));
				}
			});
	}, [threatModeActive, investigationModeActive]);

	return (
		<>
			{threatModeActive && (
				<div className='threat-active'>
					<h2 className='threat-active__title'>THREAT MODE ACTIVE:</h2>
					<p className='threat-active__title'>
						All human detections to be linked with the active Threat Event.
					</p>
				</div>
			)}
			{(!threatModeActive && investigationModeActive) && (
				<div className='investigation-active'>
					<h2 className='investigation-active__title'>INVESTIGATION MODE ACTIVE:</h2>
					<p className='investigation-active__title'>
						Keep an eye on events
					</p>
				</div>
			)}
		</>
	);
};

export default ThreatActive;
