import React from 'react';
import { Box } from '@mui/material';
import { CONFIRM_TYPES, DETECTION_LABELS } from 'helpers/constants';
import './InfoHeading.scss';

const { THREAT } = DETECTION_LABELS;
const { HUMAN, OBJECT } = CONFIRM_TYPES;

const InfoHeading = ({ text, color = 'light', type = '#00D4FF' }) => {
	const textHandler = () => {
		if (text === THREAT || text === HUMAN) {
			return 'Confirm Human Status';
		}

		if (text === OBJECT) {
			return 'Confirm Other Type';
		}
	};

	return (
		<div className='info-heading'>
			<div className='info-heading__ellipse'>
				<Box className={`info-heading__ellipse-outer`} sx={{ background: type }}>
					<div className='info-heading__ellipse-inner'>
						<p className={`info-heading__ellipse-inner__text`} style={{ color: type }}>
							!
						</p>
					</div>
				</Box>
			</div>
			<div className='info-heading__info'>
				<p className={`info-heading__info-text ${color}`}>{textHandler()}</p>
			</div>
		</div>
	);
};

export default InfoHeading;
