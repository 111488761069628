import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal as MUIModal } from '@mui/material';
import { cancel } from 'store/inspection/inspectionSlice';
import './Modal.scss';

const Modal = ({ modalHeader = '', setOpen, open, children, size = 'large' }) => {
	const dispatch = useDispatch();

	const handleClose = () => {
		setOpen?.(false);
		dispatch(cancel());
	};

	return (
		<MUIModal
			open={open}
			onClose={handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<div className={`modal ${size === 'large' ? 'modal-lg' : 'modal-sm'}`}>
				<div className='modal-header'>
					<p>{modalHeader}</p>
				</div>
				<div className='modal-body'>{children}</div>
			</div>
		</MUIModal>
	);
};

export default Modal;
