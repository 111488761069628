import React, { useMemo } from 'react';
import './Badge.scss';

const Badge = ({ items, variant = '' }) => {
	const checkBadgeCount = count => {
		if (+count >= 100) {
			return '99+';
		}
		return count;
	};

	const memoizedBadgeCount = useMemo(() => checkBadgeCount(items), [items]);

	return (
		<div className={`badge ${variant === 'dot' ? 'dot' : ''}`}>
			<span>{memoizedBadgeCount}</span>
		</div>
	);
};

export default Badge;
