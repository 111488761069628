import { createTheme, Tooltip } from '@mui/material';
import { THEME_COLORS } from 'helpers/constants';
import { withStyles } from '@mui/styles';

const { SECONDARY, DANGER, SUCCESS, WARNING, PRIMARY, INFO } = THEME_COLORS;

export const theme = createTheme({
	palette: {
		primary: {
			main: PRIMARY,
		},
		success: {
			main: SUCCESS,
		},
		secondary: {
			main: SECONDARY,
		},
		warning: {
			main: WARNING,
		},
		error: {
			main: DANGER,
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1886,
		},
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					margin: '0',
				},
				maxWidthXl: {
					padding: '28px 64px',
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					height: '3px',
				},
				bar1Determinate: {
					background: '#22364F',
				},
			},
		},
		MuiTimeline: {
			styleOverrides: {
				root: {
					padding: '0',
				},
			},
		},
		MuiTimelineContent: {
			styleOverrides: {
				root: {
					padding: '0 10px',
				},
			},
		},
		MuiTimelineOppositeContent: {
			styleOverrides: {
				positionRight: {
					flex: '0 0 53px',
					padding: '0 10px',
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backdropFilter: 'blur(4px)',
				},
				arrow: {
					width: '28px',
					height: '8px',
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					backgroundColor: 'rgba(248, 249, 250, 0.85)',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					background: '#214160',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: '#F8F9FA',
					right: '12px',
					top: 'unset',
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					display: 'flex',
					alignItems: 'center',
					height: '42px',
					fontWeight: 700,
					fontSize: '15px',
					lineHeight: '18px',
					color: '#0C2E4E',
				},
				filledSuccess: {
					background: SUCCESS,
				},
				filledInfo: {
					background: INFO,
				},
				filledWarning: {
					background: WARNING,
				},
				filledError: {
					background: DANGER,
					color: '#F8F9FA',
				},
			},
		},
	},
});

export const MarkupTooltip = withStyles({
	tooltip: {
		minWidth: '320px',
		padding: '16px',
		background: 'rgba(10, 37, 64, 0.9)',
		borderRadius: '8px',
	},
	arrow: {
		color: 'rgba(10, 37, 64, 0.9)',
	},
})(Tooltip);

export const ImageOnlyTooltip = withStyles({
	tooltip: {
		padding: '20px',
		width: '290px',
		height: '290px',
		background: 'rgba(12, 46, 78, 0.85)',
		marginLeft: '24px !important',
		borderRadius: '10px',
	},
	arrow: {
		color: 'rgba(12, 46, 78, 0.85)',
	},
})(Tooltip);

export const TextOnlyTooltip = withStyles({
	tooltip: {
		padding: '8px 16px',
		width: '320px',
		minHeight: '64px',
		background: 'rgba(10, 37, 64, 0.9)',
		borderRadius: '8px',
	},
	arrow: {
		color: 'rgba(10, 37, 64, 0.9)',
	},
})(Tooltip);

export const RegularTooltip = withStyles({
	tooltip: {
		padding: '8px 16px',
		minWidth: '101px',
		minHeight: '34px',
		background: 'rgba(10, 37, 64, 0.9)',
		borderRadius: '8px',
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '18px',
		color: '#C7D5E2',
	},
	arrow: {
		color: 'rgba(10, 37, 64, 0.9)',
	},
})(Tooltip);
