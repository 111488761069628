import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocationOn, Settings } from '@mui/icons-material';
import InfoHeading from 'components/InfoHeading';
import Dialog from 'components/common/Dialog';
import { selectClient, addToChosen, leaveAllAlone } from 'store/clients/clientSlice';
import {
	changeFilterType,
	clearFilteredDetections,
	toggleEventPanel,
} from 'store/detections/detectionsSlice';
import { clientsSelector, detectionsSelector, timelineSelector } from 'helpers/reduxSelectors';
import { URLS } from 'helpers/constants';
import { ReactComponent as Grid } from 'assets/images/icons/grid.svg';
import { ReactComponent as Events } from 'assets/images/icons/warning.svg';
import { ReactComponent as Reporting } from 'assets/images/icons/chart.svg';
import './Taskbar.scss';

const { MAP_URL } = URLS;

const Taskbar = () => {
	const [openDialog, setOpenDialog] = useState(false);
	const { activeDetectionDeprecated } = useSelector(timelineSelector);
	const { chosenClients, cancelId } = useSelector(clientsSelector);
	const { isEventPanel } = useSelector(detectionsSelector);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const chooseHandler = () => {
		setOpenDialog(prev => !prev);
	};

	const continueHandler = () => {
		setOpenDialog(false);
		if (chosenClients.length > 2) {
			dispatch(leaveAllAlone(cancelId));
		} else {
			dispatch(selectClient(cancelId));
			dispatch(addToChosen(cancelId));
		}
		navigate(MAP_URL);
	};

	const eventHandler = () => {
		dispatch(toggleEventPanel());
	};

	return (
		<>
			<div className='taskbar'>
				<NavLink className='taskbar-link' to='detections'>
					<div className='taskbar-item'>
						<Grid className='taskbar-item__grid' />
					</div>
				</NavLink>
				{chosenClients.length <= 1 && (
					<NavLink className='taskbar-link disabled-link' to='map'>
						<div className='taskbar-item'>
							<LocationOn className='taskbar-item__map' />
						</div>
					</NavLink>
				)}
				{chosenClients.length > 1 && (
					<div className='taskbar-link' onClick={chooseHandler}>
						<div className='taskbar-item'>
							<LocationOn className='taskbar-item__map' />
						</div>
					</div>
				)}
				<NavLink className='taskbar-link charts disabled-link' to=''>
					<div className='taskbar-item'>
						<Reporting className='taskbar-item__reporting' />
					</div>
				</NavLink>
				<div
					className={`taskbar-item feed ${
						activeDetectionDeprecated || isEventPanel ? 'active' : ''
					}`}
					onClick={eventHandler}
				>
					<Events className='taskbar-item__event' />
				</div>
				<div className='taskbar-item settings disabled-link'>
					<Settings />
				</div>
			</div>
			<Dialog
				open={openDialog}
				title={<InfoHeading text='Multiple Clients Selected' color='dark' />}
				renderLeftBtn={() => (
					<button className='btn-primary-link' type='button' onClick={chooseHandler}>
						Cancel
					</button>
				)}
				renderRightBtn={() => (
					<button className='btn-primary-outline' onClick={continueHandler}>
						Continue to Maps View
					</button>
				)}
			>
				<p>For Maps View, please select only one client from the side panel.</p>
			</Dialog>
		</>
	);
};

export default Taskbar;
