import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import {
	editDetectionToEvent,
	editInvestigationEvent,
	editThreatEvent,
	fetchAlbumImages,
	fetchDetections,
	fetchInvestigations,
	fetchThreats,
} from './thunks';
import { APP_NAMES, DETECTION_LABELS } from 'helpers/constants';

const { INVESTIGATE, THREAT, SAFE, OTHER } = DETECTION_LABELS;
const { UNVERIFIED, ACTIVE, RESOLVED } = APP_NAMES;

const detectionsSlice = createSlice({
	name: 'detections',
	initialState,
	reducers: {
		detectionGetById: (state, { payload }) => {
			if (state.filterBy === UNVERIFIED) {
				state.chosenDetection = state.detections.find(({ id }) => id === payload);
			} else {
				state.chosenDetection = state.filteredDetections.find(({ id }) => id === payload);
				state.chosenDetection = {
					...state.chosenDetection,
					additionalInfo: state.events.find(
						({ Tnumber }) => Tnumber === state.chosenDetection.Tnumber
					),
				};
			}
		},
		clearDetectionById: state => {
			state.chosenDetection = {};
		},
		filterDetections: (state, { payload }) => {
			state.filteredDetections = [];
			payload.forEach(({ site }) => {
				state.detections.forEach(item => {
					if (item.site === site) {
						state.filteredDetections.push(item);
					}
				});

				if (!state.filterBy.length && !state.chosenEvent?.id) {
					state.filteredDetections = [];
				} else {
					state.filteredDetections = state.filteredDetections.filter(item => {
						if (state.filterBy.includes(UNVERIFIED)) {
							if (
								!item.verified ||
								(item.verified && item.threat) ||
								(item.verified && item.investigate)
							) {
								return item;
							}
						}

						if (state.filterBy.includes(SAFE)) {
							if (item.verified && !item.threat && !item.investigate && !item.other) {
								return item;
							}
						}

						if (state.filterBy.includes(OTHER)) {
							if (item.other) {
								return item;
							}
						}
					});

					if (state.chosenEvent?.id) {
						state.eventSelectedSteps = [];
						state.events
							.filter(item => item.id === state.chosenEvent.id)
							.forEach(item => {
								state.eventSelectedSteps.push(...item.steps);
								state.filteredDetections.push(
									...item.detections.map(el => ({ ...el, site: 'KSH001' }))
								);
							});
					}

					state.collation = state.filteredDetections.reduce((acc, item) => {
						if (item.detid in acc) {
							acc[item.detid].push(item);
						} else {
							acc[item.detid] = [item];
						}

						return acc;
					}, {});

					state.filteredDetections = [];
					Object.values(state.collation).map((item, idx) => {
						if (item.investigate || item.threat) {
							state.filteredDetections.push(item[idx]);
						} else {
							state.filteredDetections.push(item[0]);
						}
					});
				}
			});
		},
		changeFilterType: (state, { payload }) => {
			if (Array.isArray(payload)) {
				state.filterBy = [];
			} else {
				if (state.filterBy.includes(payload)) {
					state.filterBy = state.filterBy.filter(item => item !== payload);
				} else {
					state.filterBy.push(payload);
				}
			}
		},
		filterAll: state => {
			if (state.filterBy.length !== 3) {
				state.filterBy = [UNVERIFIED, OTHER, SAFE];
			} else {
				state.filterBy = [];
			}
		},
		clearFilteredDetections: state => {
			state.filteredDetections = [];
		},
		getFilteredEvents: (state, { payload }) => {
			state.filteredEvents = [];
			payload.forEach(({ site }) => {
				state.events.forEach(item => {
					if (item.site === site) {
						state.filteredEvents.push(item);
					}
				});

				state.filteredEvents = state.filteredEvents.filter(item => {
					if (state.filterEvents === ACTIVE && item.active) {
						return item;
					}

					if (state.filterEvents === RESOLVED && !item.active) {
						if (state.filterResolved.includes(INVESTIGATE) && item.investigate) {
							return item;
						}

						if (state.filterResolved.includes(THREAT) && item.isThreat) {
							return item;
						}
					}
				});
				state.filteredEvents = state.filteredEvents
					.sort((a, b) => new Date(a.date) - new Date(b.date))
					.reverse();
			});
		},
		toggleEventPanel: state => {
			state.isEventPanel = !state.isEventPanel;
		},
		clearEvents: state => {
			state.events = [];
			state.chosenEvent = [];
		},
		selectEvent: (state, { payload }) => {
			//previousNotes: item.steps?.previousNotes ? item.steps?.previousNotes : []
			state.chosenEvent = payload;
			if (!state.filterBy.length) {
				state.filteredDetections = [];
			}
			state.eventSelectedSteps = [];
			state.events
				.filter(item => item.id === state.chosenEvent.id)
				.forEach(item => {
					state.eventSelectedSteps.push(...item.steps);
					state.filteredDetections.push(...item.detections.map(el => ({ ...el, site: 'KSH001' })));
				});
			state.eventSelectedSteps = state.eventSelectedSteps.map(item => ({ ...item, previousNotes: item?.previousNotes ? item?.previousNotes : [] }))
		},
		clearChosenEvent: state => {
			state.chosenEvent = {};
			state.filteredDetections = [];
			state.eventSelectedSteps = [];
		},
		changeEventFilter: (state, { payload }) => {
			state.filterEvents = payload;
		},
		changeResolvedFilter: (state, { payload }) => {
			if (state.filterResolved.includes(payload)) {
				state.filterResolved = state.filterResolved.filter(item => item !== payload);
			} else {
				state.filterResolved.push(payload);
			}
		},
		changeResolvedFilterAll: state => {
			if (state.filterResolved.length !== 2) {
				state.filterResolved = [INVESTIGATE, THREAT];
			} else {
				state.filterResolved = [];
			}
			state.eventSelectedSteps = [];
		},
		insertCurrentStep: (state, { payload }) => {
			state.currentStep = payload;
		},
		cleanError: state => {
			state.error = '';
		},
		insertCollationId: (state, { payload }) => {
			if (payload[0] === 'T') {
				state.collationId = payload.slice(2);
			} else {
				state.collationId = payload;
			}
		},
		setActiveMode: (state, { payload }) => {
			state.threatModeActive = payload;
		},
		setInvestigationActiveMode: (state, { payload }) => {
			state.investigationModeActive = payload
		},
		addNewDetections: (state, { payload }) => {
			state.detections = payload.map(item => ({ ...item, site: 'KSH001' }));
		},
		addNewDetection: (state, { payload }) => {
			const isExist = state.filteredDetections.find(item => item?.id === payload?.id);
			if (!isExist) {
				//state.detections = [{ ...payload, site: 'KSH001' }, ...state.detections];
				state.detections.unshift({ ...payload, site: 'KSH001' });
			} else {
				state.detections = state.detections.map(item => {
					if (item.detid === isExist.detid) {
						return {
							...payload,
							investigate: payload.investigate,
							threat: payload.threat,
							//verified: payload.verified
						};
					}
					return item;
				});
			}
		},
		getLastOneHour: state => {
			const ONE_HOUR = 60 * 60 * 1000;
			state.total = state.filteredDetections.filter(
				item => new Date() - new Date(item?.date) <= ONE_HOUR
			).length;
		},
		clearAlbum: state => {
			state.album = [];
		},
		setLive: state => {
			state.live = !state.live;
		},
		setStepId: (state, { payload }) => {
			if (state.stepId === payload) {
				state.stepId = '';
			} else {
				state.stepId = payload;
			}
		}
	},
	extraReducers: {
		[fetchDetections.pending]: state => {
			state.loading = true;
		},
		[fetchDetections.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.detections = payload.map(item => ({ ...item, site: 'KSH001' }));
			state.error = '';
		},
		[fetchDetections.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		[fetchAlbumImages.pending]: state => {
			state.loading = true;
		},
		[fetchAlbumImages.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.album = payload.map((item, index) => ({ ...item, idx: index + 1, site: 'KSH001' }));
			state.error = '';
		},
		[fetchAlbumImages.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		[fetchInvestigations.pending]: state => {
			state.loading = true;
		},
		[fetchInvestigations.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.events = [...state.events, ...payload.map(item => {
				if (Array.isArray(state.events)) {
					let exists = false;
					state.events.forEach(event => {
						if (event.id === item.id) {
							exists = true;
						}
					});
					if (exists) {
						return undefined;
					}
					return ({ ...item, site: 'KSH001' })
				} else {
					return ({ ...item, site: 'KSH001' })
				}
			}).filter(i => i !== undefined)
			];
			state.error = '';
		},
		[fetchInvestigations.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		[fetchThreats.pending]: state => {
			state.loading = true;
		},
		[fetchThreats.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.events = [
				...state.events,
				...payload.map(item => {
					if (Array.isArray(state.events)) {
						let exists = false;
						state.events.forEach(event => {
							if (event.id === item.id) {
								exists = true;
							}
						});
						if (exists) {
							return undefined;
						}
						return ({ ...item, isThreat: true, site: 'KSH001' })
					} else {
						return ({ ...item, isThreat: true, site: 'KSH001' })
					}
				}).filter(i => i !== undefined)
				//...payload.map(item => ({ ...item, site: 'KSH001' })),
			];
			state.error = '';
		},
		[fetchThreats.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		[editDetectionToEvent.pending]: state => {
			state.loading = true;
		},
		[editDetectionToEvent.fulfilled]: (state, { payload }) => {
			state.loading = false;
			/*			if (!payload.investigate && !payload.threat && payload.verified) {
				state.filteredDetections = state.filteredDetections.filter(item => item.id !== payload.id);
			} else {
				state.filteredDetections = state.filteredDetections.map(item => {
					if (item.id === payload.id) {
						return { ...item, investigate: payload.investigate, threat: payload.threat };
					}
					return item;
				});
				if (state.isEventPanel) {
					state.isEventPanel = false;
				}
				/!*if (state.isEventPanel && !payload.linkTo && !state.threatModeActive) {
					state.filteredEvents = [{...payload}, ...state.filteredEvents];
				}*!/
			}*/
			if (state.isEventPanel) {
				state.isEventPanel = false;
			}
			state.error = '';
		},
		[editDetectionToEvent.rejected]: (state, { payload }) => {
			state.loading = false;
			console.log(payload, 'payload err');
			state.error = payload;
		},
		[editThreatEvent.pending]: state => {
			state.loading = true;
		},
		[editThreatEvent.fulfilled]: (state, { payload }) => {
			//console.log(payload, 'id payload');
			state.loading = false;
			if (state.currentStep === 4) {
				state.filteredEvents = state.filteredEvents.filter(({ id }) => id !== payload.id);
				state.filteredDetections = [];
				state.chosenEvent = {};
				state.eventSelectedSteps = [];
			} else {
				state.eventSelectedSteps = payload.steps;
			}
			state.error = '';
		},
		[editThreatEvent.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		[editInvestigationEvent.pending]: state => {
			state.updateLoading = true;
		},
		[editInvestigationEvent.fulfilled]: (state, { payload }) => {
			state.updateLoading = false;
			if (state.currentStep === 4) {
				state.filteredEvents = state.filteredEvents.filter(({ id }) => id !== payload.id);
				state.filteredDetections = [];
				state.chosenEvent = {};
				state.eventSelectedSteps = [];
			} else if (state.currentStep > 0 && state.currentStep < 4) {
				state.eventSelectedSteps = payload.steps;
			} else {
				state.filteredEvents = state.filteredEvents.map(item => {
					//if (item.id === payload.id) {
					return { ...item, id: payload.id, threat: true };
					//}
					//return item;
				});
			}

			state.error = '';
		},
		[editInvestigationEvent.rejected]: (state, { payload }) => {
			state.updateLoading = false;
			state.error = payload;
		},
	},
});

export const {
	toggleEventPanel,
	selectEvent,
	clearChosenEvent,
	filterAll,
	clearEvents,
	getFilteredEvents,
	cleanError,
	detectionGetById,
	clearDetectionById,
	changeFilterType,
	filterDetections,
	insertCurrentStep,
	changeEventFilter,
	changeResolvedFilter,
	changeResolvedFilterAll,
	clearFilteredDetections,
	insertCollationId,
	setActiveMode,
	setInvestigationActiveMode,
	addNewDetections,
	addNewDetection,
	getLastOneHour,
	clearAlbum,
	setLive,
	setStepId
} = detectionsSlice.actions;
export default detectionsSlice.reducer;
