const initialState = {
	alertsDetections: [
		{
			id: '990f4fdc-935e-4765-aa99-dad86c8c55d3',
			name: 'Person at Camera Location',
			timestamp: Date.now(),
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.753949, 27.80921',
		},
		{
			id: 'c12bca27-9b45-403e-976b-b3aedf31e329',
			name: 'Person at Camera Location',
			timestamp: '2022-08-21T09:17:00Z',
			percent: 62,
			safe: false,
			resolved: true,
			pillar: false,
			threat: false,
			investigate: true,
			cameraCoordinates: '-25.755335, 27.808733',
		},
		{
			id: '62d68f47-86d6-4054-b116-85eff2ce92e4',
			name: 'Person at Camera Location',
			timestamp: '2022-08-20T08:01:01Z',
			percent: 62,
			safe: false,
			resolved: false,
			pillar: true,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.754233, 27.807765',
		},
		{
			id: '697ebd4c-ad74-42cf-bfb6-3350089509c9',
			name: 'Person at Camera Location',
			timestamp: '2022-08-05T12:00:00Z',
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.756063, 27.807118',
		},
		{
			id: '82834e98-63f4-4bdc-b132-40bb16abcf2d',
			name: 'Person at Camera Location',
			timestamp: '2022-08-01T13:50:00Z',
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.754223, 27.805752',
		},
		{
			id: 'c95049ea-6862-4f19-bd7a-92bb784b4689',
			name: 'Person at Camera Location',
			timestamp: '2022-07-23T09:36:00Z',
			percent: 62,
			safe: false,
			resolved: true,
			pillar: false,
			threat: true,
			investigate: false,
			cameraCoordinates: '-25.756584, 27.806811',
		},
		{
			id: '9512e34e-7537-4414-aaec-467610170509',
			name: 'Person at Camera Location',
			timestamp: '2022-07-09T09:36:00Z',
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.753949, 27.80921',
		},
		{
			id: '63e6111a-7655-4e73-af38-8d3e10666137',
			name: 'Person at Camera Location',
			timestamp: '2022-06-20T09:36:00Z',
			percent: 83,
			safe: false,
			resolved: false,
			pillar: true,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.755335, 27.808733',
		},
		{
			id: 'c8eff4c6-6f52-488b-83aa-8790fcb95495',
			name: 'Person at Camera Location',
			timestamp: '2021-08-01T09:36:00Z',
			percent: 83,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.754233, 27.807765',
		},
		{
			id: 'cd07ea83-5c38-42d5-b327-29bd86fab85b',
			name: 'Person at Camera Location',
			timestamp: '2019-02-01T09:36:00Z',
			percent: 83,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.756063, 27.807118',
		},
		{
			id: 'dab591b0-5f84-4dfa-bc14-f48e051be066',
			name: 'Person at Camera Location',
			timestamp: '2022-08-01T09:36:00Z',
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.754223, 27.805752',
		},
		{
			id: '7864b4ef-fcb7-4409-92c8-ec3129f0521a',
			name: 'Person at Camera Location',
			timestamp: '2022-08-01T09:36:00Z',
			percent: 90,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.753949, 27.80921',
		},
		{
			id: '5ee46a79-dbba-4593-8963-bd852758519b',
			name: 'Person at Camera Location',
			timestamp: '2022-08-01T09:36:00Z',
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.755335, 27.808733',
		},
		{
			id: 'f0c04a04-9c82-462f-b8ce-674d6e2439e1',
			name: 'Person at Camera Location',
			timestamp: '2022-08-01T09:36:00Z',
			percent: 92,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.754233, 27.807765',
		},
		{
			id: '4dafbb54-3926-45ff-92e6-8d36ea908cd2',
			name: 'Person at Camera Location',
			timestamp: '2022-08-01T09:36:00Z',
			percent: 62,
			safe: true,
			resolved: false,
			pillar: false,
			threat: false,
			investigate: false,
			cameraCoordinates: '-25.754223, 27.805752',
		},
	],
	activeDetectionDeprecated: '',
};

export default initialState;
