import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAllClient } from 'store/clients/clientSlice';
import { clientsSelector } from 'helpers/reduxSelectors';
import { URLS } from 'helpers/constants';
import './FilterAll.scss';

const { MAP_URL } = URLS;

const FilterAll = () => {
	const { selectAll } = useSelector(clientsSelector);
	const dispatch = useDispatch();
	const location = useLocation();

	const selectAllHandler = () => {
		if (location.pathname !== MAP_URL) {
			dispatch(selectAllClient(!selectAll));
		}
	};

	return (
		<div className={`client-panel__filter ${selectAll ? 'all' : ''}`} onClick={selectAllHandler}>
			<p className='client-panel__filter-text'>ALL</p>
		</div>
	);
};

export default FilterAll;
