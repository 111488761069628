import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth/authSlice';
import jwt_decode from 'jwt-decode';
import './Logout.scss';

const Logout = ({ onClose }) => {
	const dispatch = useDispatch();

	const { username } = jwt_decode(sessionStorage.getItem('token'));

	const logoutHandler = () => {
		dispatch(logout());
	};

	return (
		<div className='logout'>
			<h3 className='logout-title'>Logged in as : {username}</h3>
			<div className='logout-actions'>
				<button className='btn-info-link' type='button' onClick={onClose}>
					Cancel
				</button>
				<button className='btn-danger-outline' onClick={logoutHandler}>
					Log Out
				</button>
			</div>
		</div>
	);
};

export default Logout;
