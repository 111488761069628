import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	getAlbumImages,
	getDetections,
	getInvestigations,
	getThreats,
	updateDetection,
	updateInvestigationEvent,
	updateThreatEvent,
} from 'services/detections-service';

export const fetchDetections = createAsyncThunk(
	'detections/fetchDetections',
	async (_, { rejectWithValue }) =>
		getDetections()
			.then(({ data }) => data)
			.catch(e => rejectWithValue(e.message))
);

export const fetchAlbumImages = createAsyncThunk(
	'detections/fetchAlbumImages',
	async (detid, { rejectWithValue }) => {
		return getAlbumImages({ params: { detid } })
			.then(({ data }) => data)
			.catch(e => rejectWithValue(e.message));
	}
);

export const fetchInvestigations = createAsyncThunk(
	'detections/fetchInvestigations',
	async (info, { rejectWithValue }) => {
		const { active } = info;

		return getInvestigations({ params: { active } })
			.then(({ data }) => data)
			.catch(e => rejectWithValue(e.message));
	}
);

export const fetchThreats = createAsyncThunk(
	'detections/fetchThreats',
	async (info, { rejectWithValue }) => {
		const { active } = info;

		return getThreats({ params: { active } })
			.then(({ data }) => data)
			.catch(e => rejectWithValue(e.message));
	}
);

export const editDetectionToEvent = createAsyncThunk(
	'detections/editDetectionToEvent',
	async (updatedData, { rejectWithValue }) => {
		const { id, body } = updatedData;

		return updateDetection(id, body)
			.then(({ data }) => data)
			.catch(({ response: { data } }) => rejectWithValue(data.message));
	}
);

export const editThreatEvent = createAsyncThunk(
	'detections/editThreatEvent',
	async (updatedData, { rejectWithValue }) => {
		const { id, body } = updatedData;

		return updateThreatEvent(id, body)
			.then(({ data: { steps } }) => ({ steps, id }))
			.catch(e => rejectWithValue(e));
	}
);

export const editInvestigationEvent = createAsyncThunk(
	'detections/editInvestigationEvent',
	async (updatedData, { rejectWithValue }) => {
		const { id, body } = updatedData;

		return updateInvestigationEvent(id, body)
			.then(({ data }) => {
				console.log(data, 'data');
				console.log(id, 'id');
				return { steps: data.steps, id: data.id };
			})
			.catch(({ response: { data } }) => rejectWithValue(data.message));
	}
);
