import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Search from './components/Search';
import Taskbar from './components/Taskbar';
import ThreatActive from 'components/ThreatActive';
import { clientsSelector } from 'helpers/reduxSelectors';
import './Header.scss';

const Header = () => {
	const { chosenClients } = useSelector(clientsSelector);

	const clientsNameHandler = clients => {
		if (clients.length === 1) {
			return clients[0].name;
		}

		if (clients.length > 1) {
			return 'Viewing Multiple Sites';
		}

		return '';
	};

	const memoizedClientsName = useMemo(() => clientsNameHandler(chosenClients), [chosenClients]);

	return (
		<header className='header'>
			<div className='header-right'>
				<p className='header-right__name'>{memoizedClientsName}</p>
			</div>
			<ThreatActive />
			<div className='header-left'>
				<Search />
				<Taskbar />
			</div>
		</header>
	);
};

export default Header;
