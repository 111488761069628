import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import useDebounce from 'hooks/useDebounce';
import { SEARCH_RESPONSE } from './data';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import './Search.scss';

const Search = () => {
	const [filteredDetection, setFilteredDetection] = useState([]);
	const [filterInputValue, setFilterInputValue] = useState('');

	const debouncedSearch = useDebounce(filterInputValue, 500);

	useEffect(() => {
		if (debouncedSearch) {
			filterMessages();
		}
	}, [debouncedSearch]);

	const filterMessages = () => {
		const filteredData = SEARCH_RESPONSE.filter(item =>
			item.toLowerCase().includes(filterInputValue.toLowerCase())
		);
		setFilteredDetection(filteredData);
	};

	const handleInputValue = e => {
		setFilterInputValue(e.target.value);
	};

	return (
		<div className='search'>
			<div className='search-inner'>
				<label>
					<SearchIcon className='search-inner__icon' />
					<input
						className='search-inner__field'
						type='text'
						value={filterInputValue}
						onChange={handleInputValue}
						placeholder='Find Detection ID or Patroller...'
					/>
				</label>
			</div>
			{filterInputValue && (
				<div className='search-response'>
					{filteredDetection.map(item => (
						<NavLink className='search-response__result' to='' key={item}>
							{item}
						</NavLink>
					))}
				</div>
			)}
		</div>
	);
};

export default Search;
