import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CryptoJS from 'crypto-js';
import Checkbox from 'components/common/Checkbox';
import Footer from './components/Footer';
import { signIn } from 'store/auth/thunks';
import { TOAST_TYPE, VALIDATE_MESSAGES } from 'helpers/constants';
import { authSelector } from 'helpers/reduxSelectors';
import { showToast } from 'store/toast/toastSlice';
import { cleanError } from 'store/detections/detectionsSlice';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/arrow_right.svg';
import fullLogo from 'assets/images/logos/full_logo.png';
import './Login.scss';

const { FIELD_REQUIRED } = VALIDATE_MESSAGES;
const { ERROR } = TOAST_TYPE;

const Login = () => {
	const [isEmail, setIsEmail] = useState(false);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isValid },
	} = useForm({ mode: 'onChange' });

	const { loading } = useSelector(authSelector);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const subscription = watch(v => v);
		return () => subscription.unsubscribe();
	}, [watch]);

	const emailHandler = () => {
		setIsEmail(true);
	};

	const onSubmit = data => {
		let ciphertext = CryptoJS.AES.encrypt(
			JSON.stringify(data.password),
			process.env.REACT_APP_PUBLIC_KEY
		).toString();
		const signUpData = {
			username: data.email,
			password: ciphertext,
		};

		if (isEmail) {
			dispatch(signIn(signUpData))
				.unwrap()
				.then(token => {
					if (token) {
						navigate('/detections');
					}
				})
				.catch(message => {
					dispatch(showToast({ message, type: ERROR }));
					dispatch(cleanError());
				});
		}
	};

	return (
		<div className='login'>
			<div className='login-wrapper'>
				<div className='login-content'>
					<img src={fullLogo} alt='ultra sense' />
					{loading && (
						<div className='login-loader'>
							Loading
							<div className='dot-typing'></div>
						</div>
					)}
					{!loading && (
						<>
							<h1 className='login-content__heading' data-testid='login-heading'>
								Sign in to Ultraguard
							</h1>
							<form onSubmit={handleSubmit(onSubmit)}>
								<label className='login-form'>
									<input
										className='login-form__field'
										data-testid='email-field'
										type='text'
										placeholder='User e-mail'
										{...register('email', {
											required: FIELD_REQUIRED,
										})}
									/>
									{!isEmail && (
										<button
											className='login-form__icon'
											data-testid='btn-email'
											type='button'
											disabled={!isValid}
											onClick={emailHandler}
										>
											<ArrowLeft className='login-form__icon-arrow' />
										</button>
									)}
								</label>
								<label className={`login-form password ${isEmail ? 'opacity' : ''}`}>
									{isEmail && (
										<input
											className='login-form__field'
											type='password'
											placeholder='Password'
											{...register('password', {
												required: FIELD_REQUIRED,
											})}
										/>
									)}
									<button className='login-form__icon' type='submit'>
										<ArrowLeft className='login-form__icon-arrow' />
									</button>
								</label>
								<div className={`login-errors ${errors ? 'opacity' : ''}`}>
									{errors.email && (
										<span className='error' data-testid='error-email'>
											{errors.email?.message}
										</span>
									)}
									{errors.password && <span className='error'>{errors.password?.message}</span>}
								</div>
								<div className='login-signed'>
									<Checkbox label='Keep me signed in' register={register('keep')} />
								</div>
								<div className='login-forgot'>
									<Link className='login-forgot__link' to=''>
										Forgot e-mail or password?
									</Link>
								</div>
							</form>
						</>
					)}
				</div>
			</div>
			{!loading && (
				<div className='login-footer'>
					<Footer />
				</div>
			)}
		</div>
	);
};

export default Login;
